import { useContext, defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { computed, reactive } from '@vue/composition-api';
import { isFlutter, parseAdfoxHTML } from '~/utils';
export default defineComponent({
    name: 'SliderSlidePartnerAdfox',
    inheritAttrs: false,
    props: {
        config: {
            required: true,
            type: Object,
        },
    },
    emits: ['complete'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const context = useContext();
        const rootEl = ref();
        const slide = reactive({
            component: null,
            article: {},
        });
        const shouldRenderAds = computed(() => !isFlutter(context.$device.userAgent));
        function onComplete({ error = {}, state }) {
            emit('complete', { error, state });
            if (!rootEl.value) {
                return;
            }
            const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
            if (!adfoxEl && !state.isRendered) {
                return;
            }
            // Parse HTMl in Adfox container
            slide.article = parseAdfoxHTML(adfoxEl);
            slide.component = () => import('~/components/SliderSlidePartner/SliderSlidePartner.vue');
        }
        return {
            css,
            onComplete,
            rootEl,
            slide,
            shouldRenderAds,
        };
    },
});
