import { computed, defineComponent, onBeforeUnmount, onMounted, provide, reactive, ref, useCssModule, } from '@nuxtjs/composition-api';
import { ResizeObserver } from 'resize-observer';
import { DEFAULT_OPTIONS } from '.';
import { getArticleInfo } from '~/utils';
import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
import { PODCASTS_PARTNERS } from '~/components/PodcastPartner';
export default defineComponent({
    name: 'Slider',
    props: {
        items: {
            required: true,
            type: Array,
        },
        options: {
            default: () => ({}),
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const rootEl = ref();
        const itemsEl = ref();
        const resizeObserver = ref();
        const scrollCurrent = ref(-1);
        const scrollWidth = ref(0);
        const canScrollBackward = computed(() => scrollCurrent.value > 0);
        const canScrollForward = computed(() => scrollCurrent.value < scrollWidth.value);
        const isArticle = computed(() => props.options.type === 'article');
        const isPartner = computed(() => props.options.type === 'partner');
        const isPodcast = computed(() => props.options.type === 'podcast');
        const isSpecial = computed(() => props.options.type === 'special');
        const isVideo = computed(() => props.options.type === 'video');
        const isStory = computed(() => props.options.type === 'story');
        const withButtons = ref(true);
        /**
         * Current options.
         */
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        /**
         * Provides slider options
         */
        provide('sliderOptions', currentOptions.value);
        /**
         * Slide Components
         */
        const slideComponents = {
            article: () => import('~/components/SliderSlide/SliderSlide.vue'),
            partner: () => import('~/components/SliderSlidePartner/SliderSlidePartner.vue'),
            podcast: () => import('~/components/SliderSlidePodcast/SliderSlidePodcast.vue'),
            special: () => import('~/components/SliderSlidePartner/SliderSlidePartner.vue'),
            video: () => import('~/components/SliderSlideVideo/SliderSlideVideo.vue'),
            story: () => import('~/components/SliderSlide/SliderSlide.vue'),
        };
        /**
         * Indicates that uses default width & gap (not from outer options)
         */
        const withDefaultItemSizes = computed(() => {
            const { itemWidth, itemGap } = props.options;
            const { itemWidth: defaultWidth, itemGap: defaultGap } = DEFAULT_OPTIONS;
            return itemWidth === defaultWidth && itemGap === defaultGap;
        });
        /**
         * Current formatted items
         */
        const currentItems = computed(() => {
            if (!(props.items || []).length) {
                return [];
            }
            const items = props.items?.slice(0, currentOptions.value.length) || [];
            return items?.map(({ data }) => {
                const { podcast_file: file, photo, photo_present: photoPresent, serial, title } = data;
                const additionalInfo = getArticleInfo.call(root, data);
                let { path } = additionalInfo || {};
                if (!path.includes('utm_')) {
                    path = `${path}?utm_source=forbes&utm_campaign=${currentOptions.value.slug}`;
                }
                return {
                    photo,
                    photo_present: photoPresent,
                    title,
                    ...additionalInfo,
                    path,
                    ...(file && { file }),
                    ...(serial && { serial }),
                    ...(serial && { isPartner: serial.url in PODCASTS_PARTNERS() }),
                };
            });
        });
        /**
         * Adfox items
         */
        const adfoxItems = {
            1: reactive({
                config: {
                    ...ADFOX_CODES_DESKTOP[`${props.options?.type}_1`],
                    containerId: `adfox__slider__${props.options?.type}__1`,
                },
                isVisible: true,
            }),
            2: reactive({
                config: {
                    ...ADFOX_CODES_DESKTOP[`${props.options?.type}_2`],
                    containerId: `adfox__slider__${props.options?.type}__2`,
                },
                isVisible: true,
            }),
            3: reactive({
                config: {
                    ...ADFOX_CODES_DESKTOP[`${props.options?.type}_3`],
                    containerId: `adfox__slider__${props.options?.type}__3`,
                },
                isVisible: true,
            }),
            4: reactive({
                config: {
                    ...ADFOX_CODES_DESKTOP[`${props.options?.type}_4`],
                    containerId: `adfox__slider__${props.options?.type}__4`,
                },
                isVisible: true,
            }),
        };
        /**
         * Handles "scroll" event.
         */
        function handleScroll() {
            scrollCurrent.value = itemsEl.value.scrollLeft;
        }
        /**
         * Handles completing adfox
         */
        function onAdfoxComplete(adfoxItem, { state }) {
            adfoxItem.isVisible = state.isLoaded || state.isRendered;
        }
        /**
         * Scroll's to a previous item.
         */
        function scrollBackward() {
            if (!canScrollBackward) {
                return;
            }
            const { children } = itemsEl.value;
            let i = children.length;
            while (i--) {
                const child = children[i - 1];
                if (!child) {
                    continue;
                }
                if (i !== 1 && scrollCurrent.value < child.offsetLeft + child.offsetWidth - 16) {
                    continue;
                }
                const left = child.offsetLeft;
                return itemsEl.value.scrollTo({ behavior: 'smooth', left });
            }
        }
        /**
         * Scroll's to a next item.
         */
        function scrollForward() {
            if (!canScrollForward) {
                return;
            }
            const { children } = itemsEl.value;
            for (let i = 0, l = children.length; i < l; i++) {
                const child = children[i];
                const currentScroll = scrollCurrent.value;
                const totalScroll = scrollWidth.value;
                if (currentScroll > child.offsetWidth + child.offsetLeft - 16) {
                    continue;
                }
                const nextChild = children[i + 1];
                if (!nextChild) {
                    continue;
                }
                let left = nextChild.offsetLeft;
                if (totalScroll - currentScroll < nextChild.offsetWidth) {
                    left = totalScroll;
                }
                return itemsEl.value.scrollTo({ behavior: 'smooth', left });
            }
        }
        onBeforeUnmount(() => {
            ;
            itemsEl.value.removeEventListener('scroll', handleScroll);
            resizeObserver.value?.unobserve(itemsEl.value);
        });
        onMounted(() => {
            if (!itemsEl.value || !rootEl.value) {
                return;
            }
            itemsEl.value.addEventListener('scroll', handleScroll);
            resizeObserver.value = new ResizeObserver(() => {
                scrollCurrent.value = 0;
                const { scrollWidth: currentScrollWidth } = itemsEl.value;
                const { offsetWidth } = rootEl.value;
                scrollWidth.value = currentScrollWidth - offsetWidth;
                withButtons.value = currentScrollWidth > offsetWidth;
            });
            resizeObserver.value.observe(itemsEl.value);
        });
        return {
            adfoxItems,
            canScrollBackward,
            canScrollForward,
            css,
            currentItems,
            currentOptions,
            isArticle,
            isPartner,
            isPodcast,
            isSpecial,
            isStory,
            isVideo,
            itemsEl,
            onAdfoxComplete,
            resizeObserver,
            scrollBackward,
            scrollCurrent,
            scrollForward,
            scrollWidth,
            slideComponents,
            rootEl,
            withButtons,
            withDefaultItemSizes,
        };
    },
});
