// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xoH5k,.xoH5k [id^=adfox],.EI1jh,.\\+n9-S{height:100%}.\\+n9-S{position:relative}.nZ6Fl{padding-top:0!important}.ZDxb2{position:absolute;top:0;left:16px;width:calc(100% - 32px);height:128px;background-color:#e2e2e2;border-radius:4px}.F6BZy{position:absolute;top:140px;height:72px}.F6BZy>:last-child{margin-top:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adfox": "xoH5k",
	"root": "EI1jh",
	"slideBox": "+n9-S",
	"slide": "nZ6Fl",
	"loader": "ZDxb2",
	"loaderLines": "F6BZy"
};
module.exports = ___CSS_LOADER_EXPORT___;
