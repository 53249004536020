export const DEFAULT_OPTIONS = {
    entryTheme: 'blue',
    itemGap: '32px',
    itemWidth: '284px',
    length: 12,
    theme: 'dark',
    toolbarPosition: 'top',
    type: 'article',
    withShadows: true,
    withToolbar: true,
};
