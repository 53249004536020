/**
 * Podcasts partners
 * @param pr https://clck.ru/WyLwY
 * @constructor
 */
export const PODCASTS_PARTNERS = (pr = 0) => ({
    'podcasts/androidy-i-elektroovcy': {
        logo: () => import('~/assets/images/x5.svg?inline'),
        to: `//ads.adfox.ru/162070/goLink?p1=cqocf&p2=frfe&p5=roxuo&pr=${pr}&puid1=&puid2=&puid3=`,
        tooltip: 'Одновременно визионерский и прикладной подкаст Forbes и X5Group о профессионалах будущего.',
    },
    'podcasts/kassovyy-razryv': {
        logo: () => import('~/assets/images/mastercard.svg?inline'),
        // to: `//ads.adfox.ru/162070/goLink?p1=cmloh&p2=frfe&p5=iossn&pr=${pr}&puid1=`,
        tooltip: `Этот подкаст мы сделали вместе с Mastercard. Получайте бонусы с каждой покупки по бизнес-карте Mastercard и обменивайте их на предложения и скидки от партнеров. Например, вы можете купить и разместить рекламу со скидкой, оплатить такси или купить что-то для офиса.`,
    },
});
